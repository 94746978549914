<template>
  <div style="height: 100% !important">
    <v-container class="page-x" v-if="isLoading">
      <div class="page-content text-h3">It’s Quiz Time!</div>
    </v-container>
    <v-container class="page" v-else>
      <div class="page-header pb-2">
        <v-btn @click="close()" icon color="blue darken-2">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <div class="page-content">
        <v-img
          :src="require('@/mockups/ABC/book-1/assets/images/1 6-0.png')"
          max-width="250"
        >
        </v-img>
        <div class="my-2">Where do Hamochi crafts items at night?</div>
        <v-btn
          width="200"
          max-width="100%"
          :color="isAnswered && answer != 3 ? 'error' : 'black'"
          outlined
          depressed
          dark
          @click="clickAnswer(1)"
          class="my-1 justify-start text-capitalize"
        >
          (A) Village
        </v-btn>
        <v-btn
          width="200"
          max-width="100%"
          :color="isAnswered && answer != 3 ? 'error' : 'black'"
          outlined
          depressed
          dark
          @click="clickAnswer(2)"
          class="my-1 justify-start text-capitalize"
        >
          (B) School
        </v-btn>
        <v-btn
          width="200"
          max-width="100%"
          :color="isAnswered ? 'blue darken-2' : 'black'"
          outlined
          depressed
          dark
          @click="clickAnswer(3)"
          class="my-1 justify-start text-capitalize"
        >
          (C) Home
        </v-btn>

        <!-- <v-radio-group v-model="answer" column @change="isAnswered = true">
          <v-card
            outlined
            class="my-1 pe-2 ps-4 py-2 tex-center d-flex justify-space-between"
            width="200"
            max-width="100%"
          >
            (A) Village
            <v-radio color="error" :value="1" :readonly="isAnswered"></v-radio>
          </v-card>
          <v-card
            outlined
            class="my-1 pe-2 ps-4 py-2 tex-center d-flex justify-space-between"
            width="200"
            max-width="100%"
          >
            (B) School
            <v-radio color="error" :value="2" :readonly="isAnswered"></v-radio>
          </v-card>
          <v-card
            outlined
            class="my-1 pe-2 ps-4 py-2 tex-center d-flex justify-space-between"
            width="200"
            max-width="100%"
          >
            (C) Home
            <v-radio
              color="blue darken-2"
              :value="3"
              :readonly="isAnswered"
            ></v-radio>
          </v-card>
        </v-radio-group> -->
      </div>
      <div class="mb-8"></div>
    </v-container>
    <div class="page-footer py-2 px-4" v-if="isAnswered">
      {{
        answer == 3 ? "Yes. You are correct." : "Oh.. no. You are incorrect."
      }}
      <v-btn color="blue darken-2" depressed dark @click="goNext"> next </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    autoplay: false,
    language: 1,
    answer: null,
    isLoading: true,
    isAnswered: false,
    timer: null,
  }),
  created() {
    //
  },
  mounted() {
    if (this.$route.query.lang) {
      this.language = this.$route.query.lang;
    }

    this.autoplay =
      this.$route.query.autoplay && this.$route.query.autoplay == 1
        ? true
        : false;

    this.timer = setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  },
  methods: {
    goPrevious() {
      this.$router.push({
        name: "ABCPage9",
        query: { autoplay: this.autoplay ? 1 : 0, lang: this.language },
      });
    },
    goNext() {
      this.$router.push({
        name: this.answer == 3 ? "ABCPage11" : "ABCPage12",
        query: { autoplay: this.autoplay ? 1 : 0, lang: this.language },
      });
    },
    close() {
      this.$router.push({
        name: "ABCPage2",
      });
    },
    clickAnswer(answer) {
      if (!this.isAnswered) {
        this.isAnswered = true;
        this.answer = answer;
      }
    },
  },
};
</script>

<style scoped>
.page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: #000 solid 1px;

  position: fixed;
  z-index: 99;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  max-width: 480px;
}

.page {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  border: #000 solid 1px;
  background: rgb(255, 255, 255) !important;
}

.page-x {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: #000 solid 1px;
  background: rgb(255, 255, 255) !important;
}

.buttons {
  display: flex;
}

.btn-dis {
  opacity: 0.5;
}

.vocab {
  text-decoration: underline;
  font-weight: 900;
}
</style>